<script setup></script>
<template>
  <section>
    <h1 class="notfound">404</h1>

    <h1>{{ $t.translate("TTL_NOT_FOUND") }}</h1>
    <p>{{ $t.translate("TTL_NOT_FOUND_HELPER") }}</p>
  </section>
</template>
<style scoped>
h1,
p {
  color: #222;
}
.notfound {
  font-size: 120px;
  font-weight: bold;
  opacity: 0.5;
}
section {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 150px;
  margin-bottom: 30px;
}
</style>
